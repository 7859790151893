const information = [
  {
    id: 1,
    title: 'Welcome to Amina Shop',
  },
  {
    id: 2,
    title: 'Senegal, Dakar',
  },
  {
    id: 3,
    title: '+221 775190104',
  },
]

export default information
