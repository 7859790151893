import React from 'react'
import a from "../assets/d.png"
const Delivery = () => {
  return (
    <div className='banner'>
      <h2>Efficient Delivery Services</h2>
      <div className='banner-content delivery'>
        <h2>Speedy Delivery</h2>
        <h2>Reliable Shipping</h2>
        <h2>Efficient Dispatch</h2>
      </div>
      <img src={a} alt='' />
    </div>
  )
}

export default Delivery
